<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card invoice-card">
        <div class="card-body p-5">
          <div class="row mb-4">
            <div class="col-md-5"></div>
            <div class="col-md-3"></div>
            <div class="col-md-4">
              <div class="invoice-logo">
                <img src="@/assets/images/svg/logo.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <h4 class="mb-1">{{ $t("Billing Address") }}</h4>
              <p>{{ form.companyId.companyName }}</p>
              <p>{{ form.companyId.addressLine1 }}</p>
              <p>{{ form.companyId.zipCode }} {{ form.companyId.city }}</p>
              <p>{{ form.companyId.country }}</p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <h4 class="mb-1">{{ $t("Customer Details") }}</h4>
              <p>
                <strong>{{ $t("Customer") }}</strong>
                <span class="float-right">{{
                  form.companyId.companyName
                }}</span>
              </p>
              <p>
                <strong>{{ $t("Start Date") }}</strong>
                <span class="float-right">{{ form.startDate }}</span>
              </p>
              <p>
                <strong>{{ $t("End Date") }}</strong>
                <span class="float-right">{{ form.startDate }}</span>
              </p>
              <p>
                <strong>{{ $t("Status") }}</strong>
                <span class="float-right">{{ form.status }}</span>
              </p>
              <p>
                <strong>{{ $t("Created By") }}</strong>
                <span class="float-right"
                  >{{ user.firstName }} {{ user.lastName }}</span
                >
              </p>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <p>
                <strong>{{ $t("Customer Nr") }}.</strong>
                <span class="float-right">{{
                  form.companyId.companyNumber
                }}</span>
              </p>
              <p>
                <strong>{{ $t("Invoice Nr") }}.</strong>
                <span class="float-right">{{ form.invoiceNumber }}</span>
              </p>
              <p>
                <strong>{{ $t("Invoice Type") }}</strong>
                <span class="float-right">{{ form.invoiceType }}</span>
              </p>
              <p>
                <strong>{{ $t("Invoice Date") }}</strong>
                <span class="float-right">{{ form.invoiceDate }}</span>
              </p>
              <p>
                <strong>{{ $t("Due Date") }}</strong>
                <span class="float-right">{{ form.dueDate }}</span>
              </p>
              <p v-if="form.externalOrderNumber">
                <strong>{{ $t("External Order Number") }}</strong>
                <span class="float-right">{{ form.externalOrderNumber }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-5">
            <table class="vgt-table striped">
              <thead>
                <tr>
                  <th>{{ $t("POS") }}</th>
                  <th>{{ $t("Article Number") }}</th>
                  <th>{{ $t("Product Name") }}</th>
                  <th>{{ $t("Quantity") }}</th>
                  <th>{{ $t("Tax") }}</th>
                  <th>{{ $t("Product Price") }}</th>
                  <th>{{ $t("Netto Total") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(products, index) in form.products" :key="index">
                  <td>{{ products.pos }}</td>
                  <td>{{ products.articleNumber }}</td>
                  <td>{{ products.productName }}</td>
                  <td>{{ products.quantity }}</td>
                  <td>{{ products.tax }}%</td>
                  <td>
                    {{
                      $formatter(
                        products.productPrice,
                        $i18n.locale,
                        "EUR",
                        false,
                        2,
                        2
                      )
                    }}
                  </td>
                  <td>
                    {{
                      $formatter(
                        products.nettoTotal,
                        $i18n.locale,
                        "EUR",
                        false,
                        2,
                        2
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="form.products.length > 0"
            class="d-flex align-items-center justify-content-end mt-2"
          >
            <div class="product-total">
              <ul>
                <li>
                  <h3>{{ $t("Netto") }}</h3>
                  <p>
                    {{
                      $formatter(totalNetto, $i18n.locale, "EUR", false, 2, 2)
                    }}
                  </p>
                </li>
                <div v-if="!form.applyReverseCharge">
                  <li v-for="(tax, index) in calculateTax" :key="index">
                    <h3>Tax {{ tax.percent }}%</h3>
                    <p>
                      {{
                        $formatter(tax.amount, $i18n.locale, "EUR", false, 2, 2)
                      }}
                    </p>
                  </li>
                </div>
                <li class="total">
                  <h3>{{ $t("Total") }}</h3>
                  <p>
                    {{
                      $formatter(totalAmount, $i18n.locale, "EUR", false, 2, 2)
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          :disabled="isLoading"
          variant="primary"
          @click="generate('pdf')"
          class="d-flex align-items-center gap-1 mr-2"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Generate PDF") }}</span>
        </b-button>
        <b-button
          :disabled="isLoading"
          variant="primary"
          @click="generate('docx')"
          class="d-flex align-items-center gap-1 mr-2"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Generate Docx") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PageHeader,
  },
  computed: {
    totalNetto() {
      return this.form.products.reduce((sum, product) => {
        return sum + parseFloat(product.nettoTotal || 0);
      }, 0);
    },
    totalAmount() {
      // Sum all the taxes from calculateTax and add totalNetto
      const totalTaxes = this.calculateTax.reduce((sum, tax) => {
        return sum + parseFloat(tax.amount);
      }, 0);

      return parseFloat(this.totalNetto + totalTaxes).toFixed(2);
    },
    calculateTax() {
      if (this.form.applyReverseCharge) {
        // If reverse charge is applied, all amounts should be zero
        return Object.keys(
          this.form.products.reduce((result, product) => {
            result[product.tax] = 0;
            return result;
          }, {})
        ).map((tax) => ({
          percent: tax,
          amount: "0.00",
        }));
      }

      const groupedProducts = this.form.products.reduce((result, product) => {
        const tax = product.tax;
        if (!result[tax]) {
          result[tax] = [];
        }
        result[tax].push(product);
        return result;
      }, {});

      const result = Object.entries(groupedProducts).map(([tax, products]) => ({
        percent: tax,
        amount: products.reduce((sum, product) => {
          const nettoTotal = parseFloat(product.nettoTotal) || 0;
          const taxAmount = (nettoTotal * parseFloat(tax)) / 100;
          return parseFloat(sum + taxAmount).toFixed(2);
        }, 0),
      }));

      return result;
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Invoices"),
          to: "/invoices",
        },
        {
          text: this.$t("Show"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      statusOptions: [],
      invoiceStatus: "",
      form: {
        invoiceType: "",
        companyId: "",
        dueDate: "",
        startDate: "",
        endDate: "",
        totalAmount: "",
        taxAmount: "",
        invoiceDate: "",
        externalOrderNumber: "",
        applyReverseCharge: false,
        products: [],
        status: "",
        invoiceNumber: "",
      },
      referenceInvoiceDetail: "",
      isLoading: false,
      user: {
        firstName: "",
        lastName: "",
      },
      modelData: {},
    };
  },
  async created() {
    this.refresh();
    const response = await this.$store.dispatch(
      "invoices/documentAssignmentList"
    );
    this.modelData = response?.data?.data ?? {};
  },
  methods: {
    filterStatusOptions(status) {
      const allOptions = [
        "draft",
        "approved",
        "warning level 1",
        "warning level 2",
        "warning level 3",
        "sent",
        "paid",
      ];

      const statusIndex = allOptions.indexOf(status);

      if (statusIndex !== -1) {
        // Update statusOptions to remove all previous statuses
        this.statusOptions = allOptions.slice(statusIndex);
      } else {
        // If the status is not found, keep all options
        this.statusOptions = allOptions;
      }
    },
    async refresh() {
      await this.$store
        .dispatch("invoices/show", this.$route.params.id)
        .then((res) => {
          let data = res?.data?.data;
          this.filterStatusOptions(data.status);
          this.invoiceStatus = data.status;
          this.getUser(data.userId);
          this.form.invoiceType = data.invoiceType;
          this.form.companyId = data.company;
          this.form.totalAmount = data.totalAmount;
          this.form.taxAmount = data.taxAmount;
          this.form.dueDate = data.dueDate;
          this.form.startDate = data.startDate;
          this.form.invoiceNumber = data.invoiceNumber;
          this.form.endDate = data.endDate;
          this.form.invoiceDate = data.invoiceDate;
          this.form.status = data.status;
          this.referenceInvoiceDetail = data.referenceInvoiceDetail;
          this.form.externalOrderNumber = data.externalOrderNumber;
          this.form.applyReverseCharge = data.applyReverseCharge;
          this.form.products = data.products;
        });
    },
    addProducts() {
      this.form.products.push({
        pos: "",
        articleNumber: "",
        productName: "",
        productPrice: "",
        quantity: "",
        nettoTotal: "",
        tax: "",
      });
    },
    getUser(id) {
      this.$store
        .dispatch("users/show", {
          id: id,
        })
        .then(async (res) => {
          if (res.message_type === "error") {
          } else {
            var response = res?.data;

            this.user.firstName = response.first_name;
            this.user.lastName = response.last_name;
          }
        });
    },
    quantityChanged(event, index) {
      this.form.products[index].nettoTotal =
        event * this.form.products[index].productPrice;
    },
    priceChanged(event, index) {
      this.form.products[index].nettoTotal =
        event * this.form.products[index].quantity;
    },
    async deleteProducts(index) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          this.form.products.splice(index, 1);
        }
      });
    },
    async generate(documentType) {
      let templateId;
      switch (this.form.status) {
        case "invoice":
          templateId = this.modelData.invoiceTemplateId;
          break;
        case "invoice-correction":
          templateId = this.modelData.invoiceCorrectionTemplateId;
          break;
        case "invoice-storno":
          templateId = this.modelData.invoiceStornoTemplateId;
          break;
        default:
          templateId = this.modelData.invoiceTemplateId; // Fallback to invoiceTemplateId
      }
      if (templateId != "" || templateId != null) {
        // Safeguard to ensure convertDate returns a valid Date object
        const parseDate = (date) => {
          const parsedDate = this.convertDate(date);
          return parsedDate instanceof Date && !isNaN(parsedDate)
            ? parsedDate
            : new Date(date);
        };

        const invoiceDate = this.form?.invoiceDate
          ? parseDate(this.form.invoiceDate)
          : this.formatDateLite(new Date());

        const startDate = this.form?.startDate
          ? parseDate(this.form.startDate)
          : null;

        const endDate = this.form?.endDate
          ? parseDate(this.form.endDate)
          : null;

        const dueDate = this.form?.dueDate
          ? parseDate(this.form.dueDate)
          : null;

        const draftStatusChangeDate = this.form?.invoiceDate
          ? parseDate(this.form.invoiceDate)
          : null;

        // Create the payload
        const payload = {
          ...this.form,
          customers: this.form.companyId,
          totalAmount: this.form.totalAmount,
          totalTaxAmount: this.form.taxAmount,
          totalAmountWithoutTax: this.form.netto,
          invoiceDate: invoiceDate.toLocaleDateString(),
          startDate: startDate ? startDate.toLocaleDateString() : null,
          endDate: endDate ? endDate.toLocaleDateString() : null,
          dueDate: dueDate ? dueDate.toLocaleDateString() : null,
          draftStatusChangeDate: draftStatusChangeDate
            ? draftStatusChangeDate.toLocaleDateString()
            : "",
          id: templateId,
          output: documentType,
          updatedTime: Date.now(),
        };

        // Generate the filename
        const filename =
          "invoice-" +
          (this.form.invoiceNumber == null
            ? "draft"
            : this.form.invoiceNumber) +
          `.${documentType}`;

        // Dispatch the action
        const response = await this.$store.dispatch(
          "documentService/processTemplate",
          {
            data: payload,
            filename: filename,
            documentType: documentType,
          }
        );

        // If response is Blob, convert to Base64
        if (response instanceof Blob) {
          await this.convertBlobToBase64(response);
        }
      }
    },
    convertDate(dateString) {
      // Convert the date string into a Date object
      const date = new Date(dateString);

      // Convert the date to the desired format (Pakistan Standard Time)
      // Forcing timezone shift by setting the time to GMT+0500
      date.setTime(date.getTime() + 5 * 60 * 60 * 1000); // Add 5 hours

      // Return the formatted string
      return date.toString();
    },
    formatDateLite(date, showTime = false) {
      try {
        const m = date.getMonth() + 1;
        const d = date.getDate();
        return `${date.getFullYear()}-${m < 10 ? "0" : ""}${m}-${
          d < 10 ? "0" : ""
        }${d}${showTime ? " " + date.toLocaleTimeString() : ""}`;
      } catch (e) {
        return date;
      }
    },
    convertBlobToBase64(data) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onerror = (err) => {
          reject(err);
        };
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
table.vgt-table tbody tr td {
  padding: 17px 10px 17px 25px;
}
</style>
